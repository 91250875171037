<script setup>
import Section from '@/Components/Section.vue';
import Heading from '@/Components/Heading.vue';
import Badge from "@/Components/Badge.vue";

import { Swiper, SwiperSlide } from 'swiper/vue';
import { Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import { computed, onBeforeUnmount, onMounted, ref } from 'vue';
import { chunks } from '@/Components/Composable/Chunk.js';
import Loader from '@/Components/Loader.vue';

const props = defineProps( {
    profile: Object,
    interval: Number,
} );

// Set events list
const events = ref( [] );
const isLoaded = ref( false );

// Load events when mounting and subscribe to websocket
onMounted( () => {
    loadEvents();

    // Interval for every 5 minutes
    setInterval(() => {
        loadEvents();
    }, props.interval);

    window.Echo.channel( `dashboard.${props.profile.slug}` ).listen( '.events', (data) => {
        if (data?.hasChanged) {
            loadEvents();
        }
    } );
} )

// Unsubscribe from webhook when unmounting
onBeforeUnmount( () => {
    window.Echo.channel( `dashboard.${props.profile.slug}` ).stopListening( '.events' );
} )

/**
 * Load employees from API
 */
const loadEvents = () => {
    axios.get( `/api/${props.profile.slug}/events` ).then( (response) => {
        const { data } = response;

        events.value = data.events;
        isLoaded.value = true;
    } );
}

// Chunk employees for slider
const eventChunks = computed( () => {
    return chunks( events.value )
} )
</script>

<template>
    <Section>
        <Heading title="Events op de planning"/>

        <div v-if="isLoaded">
            <div class="flex grow" v-if="events.length > 0">
                <swiper
                    :slides-per-view="1"
                    :space-between="0"
                    :pagination="{ clickable: true }"
                    :grabCursor="true"
                    :modules="[Pagination]"
                    class="min-w-full w-full">
                    <swiper-slide
                        v-for="(chunk, index) in eventChunks"
                        :key="index"
                        :virtualIndex="index">
                        <table class="min-w-full w-full">
                            <tbody>
                                <tr v-for="(event, index) in chunk" :key="index">
                                    <td class="py-2.5">
                                        <Badge :type="event.badge_color">{{ event.badge }}</Badge>
                                        <h3 class="text-base font-semibold">{{ event.title }}</h3>
                                    </td>
                                    <td class="py-2.5">
                                        <span class="flex text-xs justify-end">{{ event.date_display }}</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </swiper-slide>
                </swiper>
            </div>
            <div v-else>
                <p class="py-2.5 text-sm text-gray-400">Geen events op de planning</p>
            </div>
        </div>
        <Loader v-else/>
    </Section>
</template>
